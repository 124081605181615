:root {
  --background: #ffffff;
  --text-primary: #333333;
  --border-color: #e5e5e5;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #1a1a1a;
    --text-primary: #ffffff;
    --border-color: #404040;
  }
}

body {
  margin: 0;
  font-family:
    "Raleway",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background);
  color: var(--text-primary);
}

.themed-border {
  border: 2px solid var(--border-color);
}
